<template>
  <v-container fluid class="pa-6">
    <v-row>
      <v-col cols="12">
        <v-alert outlined icon="mdi-sticker-alert-outline" type="warning">
          {{ $t('generic.lang_allergiesText') }}
        </v-alert>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col class="pt-0 pb-0" :key="allergen.id" v-for="allergen in  this.allergens" cols="12" sm="6" md="4">
            <v-checkbox v-model="selected" color="primary" :value="allergen.id" :label="allergen.name"/>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12">
        <v-btn :loading="loader" :disabled="loader" @click="save" elevation="0" color="primary" block class="mx-auto">
          {{ $t('generic.lang_save') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ENDPOINTS from "@/plugins/axios/endpoints";

export default {
  name: "UserAllergen",
  data: () => {
    return {
      allergens: [],
      selected: [],
      loader: false
    }
  },
  methods: {
    async loadData() {

      await this.axios(
          {
            url: ENDPOINTS.CANTEEN.ALLERGEN.USER.GET,
            method: 'GET'
          }
      ).then((res) => {
        if (res.data)
          this.selected = res.data.data.map((al) => al.id);
      })

      await this.axios({
        url: ENDPOINTS.CANTEEN.ALLERGEN.GET,
        method: 'GET'
      }).then((res) => {
        this.allergens = res.data.data;
      })
    },
    save() {
      this.loader = true;
      this.axios.post(ENDPOINTS.CANTEEN.ALLERGEN.USER.GET, {
        allergens: this.selected
      }).then(() => {
        this.$store.commit('alert/show', {
          message: "Success",
          color: 'success',
          visible: true
        });
      }).catch((err) => {
        this.$store.commit('alert/show', {
          message: err.message,
          color: 'error',
          visible: true
        });
      }).finally(() => {
        this.loader = false;
      })
    }
  },
  async created() {
    await this.loadData();
  }
}
</script>

<style>
.v-input--selection-controls .v-input__slot > .v-label, .v-input--selection-controls .v-radio > .v-label {
  top: 5px !important;
}
</style>
