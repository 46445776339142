<template>
  <v-container :fluid="this.$vuetify.breakpoint.smAndDown">
    <v-tabs class="text-left" color="primary" v-model="tabs">
      <v-tab>
        {{ $t('generic.lang_informations') }}
      </v-tab>
      <v-tab>
        {{ $t('generic.lang_allergies') }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tabs">
      <v-tab-item :class="this.$vuetify.breakpoint.mdAndUp? 'pa-12' : 'pt-5'">
        <user-information/>
      </v-tab-item>

      <v-tab-item>
        <user-allergen/>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import UserAllergen from "@/components/dashboard/profile/UserAllergen";
import UserInformation from "@/components/dashboard/profile/UserInformation";

export default {
  name: "index",
  components: {UserInformation, UserAllergen},
  /*async beforeRouteEnter(to, from, next) {
    const users = await window.axios.get('/canteen/users');

    next();
  },*/
  methods: {},
  data: () => {
    return {
      tabs: false,

    }
  }
}
</script>

<style scoped>

</style>
