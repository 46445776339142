<template>
  <v-container fluid>
    <v-form v-model="validForm" lazy-validation ref="form">
      <v-row class="mx-auto" :style="{
      width: this.$vuetify.breakpoint.mdAndUp? '70% !important' : '100%'
    }">

        <v-col cols="12">
          <v-alert class="white--text text-left" color="error" dismissible v-model="errors.visible">
            {{ this.errors.message }}

            <ul>
              <li v-for="(error , i) in errors.errors" :key="i">
                {{ Object.values(error)[0][0] }}
              </li>
            </ul>
          </v-alert>
        </v-col>
        <v-col cols="12">
          <v-avatar color="primary" size="128">
            <v-img src="@/assets/avatars/1.png"/>
          </v-avatar>
          <h4 class="primary--text pt-3">{{ this.fullname }}</h4>
          <span class="small">{{ this.user.username }}</span>
        </v-col>

        <v-col cols="12">
          <v-text-field :label="$t('generic.lang_emailAddress')" type="email" v-model="form.email" dense outlined/>
        </v-col>

        <v-col cols="12" class="text-left text-muted pt-0 pb-0">
          <label>{{ $t('generic.lang_changePassword') }}</label>
        </v-col>

        <v-col cols="12" sm="6">
          <v-text-field :label="$t('generic.lang_newPassword')"
                        :append-icon="visible ? 'mdi-eye' : 'mdi-eye-off'" :type="visible ? 'text' : 'password'"
                        @click:append="visible = !visible"
                        prepend-inner-icon="mdi-lock" v-model="form.password" outlined dense
          />
        </v-col>

        <v-col cols="12" sm="6">
          <v-text-field :rules="[
            v => v === this.form.password || 'confirmation not match'
        ]" :label="$t('generic.lang_confirmPassword')"
                        :append-icon="visible ? 'mdi-eye' : 'mdi-eye-off'" :type="visible ? 'text' : 'password'"
                        @click:append="visible = !visible"
                        prepend-inner-icon="mdi-lock" v-model="form.password_confirmation" outlined dense
          />
        </v-col>

        <v-col class="pa-0 ma-0" cols="12">
          <v-divider class="pa-0 ma-0"/>
        </v-col>
        <v-col cols="12" class="text-left text-muted">
          <label>{{ $t('generic.lang_repeatPassword') }}</label>
        </v-col>

        <v-col class="pt-0" cols="12">
          <v-text-field :rules="[
            v => !!v || 'Please enter your old password'
        ]" :label="$t('generic.lang_currentPassword')" :append-icon="visible2 ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="visible2 ? 'text' : 'password'"
                        @click:append="visible2 = !visible2"
                        prepend-inner-icon="mdi-lock" v-model="form.current_password" outlined dense/>
        </v-col>

        <v-col cols="12" class="pt-0">
          <v-btn @click="save" :disabled="loader || !validForm" :loading="loader" block elevation="0" class="mx-auto"
                 color="primary">
            {{ $t('generic.lang_save') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
/**
 * import plugins
 */
import {createNamespacedHelpers} from 'vuex'
import ENDPOINTS from "@/plugins/axios/endpoints";

export default {
  name: "UserInformation",
  mounted() {
    let user = window.store.getters['auth/getUser'];
    this.form.email = user.email;
  },
  methods: {
    /**
     * sav user information
     */
    save() {

      if (!this.$refs.form.validate()) return;
      this.loader = true;
      this.axios.put(ENDPOINTS.CANTEEN.USER.UPDATE + '/' + this.user.id, this.form)
          .then(() => {
            this.$store.commit('alert/show', {
              message: "Success",
              color: 'success',
              visible: true
            });
          }).catch((err) => {
        if (err.response && err.response.status === 422) {
          this.errors.message = err.response.data.message;
          this.errors.errors = err.response.data.errors;
          this.errors.visible = true;
        } else
          this.$store.commit('alert/show', {
            message: err.message,
            color: 'error',
            visible: true
          });
      }).finally(() => this.loader = false)
    }
  },
  data: () => {
    return {
      loader: false,
      visible: false,
      visible2: false,
      validForm: false,
      errors: {
        visible: false
      },
      form: {
        email: "",
        password: "",
        password_confirmation: "",
        current_password: ""
      }
    }
  },
  computed: {
    ...createNamespacedHelpers('auth').mapGetters({
      user: 'getUser'
    }),
    fullname() {
      return (this.user.first_name || '') + " " + (this.user.last_name || '')
    }
  },
}
</script>

<style scoped>

</style>
