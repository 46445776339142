var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c('v-row',{staticClass:"mx-auto",style:({
    width: this.$vuetify.breakpoint.mdAndUp? '70% !important' : '100%'
  })},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{staticClass:"white--text text-left",attrs:{"color":"error","dismissible":""},model:{value:(_vm.errors.visible),callback:function ($$v) {_vm.$set(_vm.errors, "visible", $$v)},expression:"errors.visible"}},[_vm._v(" "+_vm._s(this.errors.message)+" "),_c('ul',_vm._l((_vm.errors.errors),function(error,i){return _c('li',{key:i},[_vm._v(" "+_vm._s(Object.values(error)[0][0])+" ")])}),0)])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-avatar',{attrs:{"color":"primary","size":"128"}},[_c('v-img',{attrs:{"src":require("@/assets/avatars/1.png")}})],1),_c('h4',{staticClass:"primary--text pt-3"},[_vm._v(_vm._s(this.fullname))]),_c('span',{staticClass:"small"},[_vm._v(_vm._s(this.user.username))])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$t('generic.lang_emailAddress'),"type":"email","dense":"","outlined":""},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1),_c('v-col',{staticClass:"text-left text-muted pt-0 pb-0",attrs:{"cols":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t('generic.lang_changePassword')))])]),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t('generic.lang_newPassword'),"append-icon":_vm.visible ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.visible ? 'text' : 'password',"prepend-inner-icon":"mdi-lock","outlined":"","dense":""},on:{"click:append":function($event){_vm.visible = !_vm.visible}},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"rules":[
          function (v) { return v === this$1.form.password || 'confirmation not match'; }
      ],"label":_vm.$t('generic.lang_confirmPassword'),"append-icon":_vm.visible ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.visible ? 'text' : 'password',"prepend-inner-icon":"mdi-lock","outlined":"","dense":""},on:{"click:append":function($event){_vm.visible = !_vm.visible}},model:{value:(_vm.form.password_confirmation),callback:function ($$v) {_vm.$set(_vm.form, "password_confirmation", $$v)},expression:"form.password_confirmation"}})],1),_c('v-col',{staticClass:"pa-0 ma-0",attrs:{"cols":"12"}},[_c('v-divider',{staticClass:"pa-0 ma-0"})],1),_c('v-col',{staticClass:"text-left text-muted",attrs:{"cols":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t('generic.lang_repeatPassword')))])]),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"rules":[
          function (v) { return !!v || 'Please enter your old password'; }
      ],"label":_vm.$t('generic.lang_currentPassword'),"append-icon":_vm.visible2 ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.visible2 ? 'text' : 'password',"prepend-inner-icon":"mdi-lock","outlined":"","dense":""},on:{"click:append":function($event){_vm.visible2 = !_vm.visible2}},model:{value:(_vm.form.current_password),callback:function ($$v) {_vm.$set(_vm.form, "current_password", $$v)},expression:"form.current_password"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"mx-auto",attrs:{"disabled":_vm.loader || !_vm.validForm,"loading":_vm.loader,"block":"","elevation":"0","color":"primary"},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('generic.lang_save'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }